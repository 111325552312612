<template>
  <div id="right6">
    <div class="title">商户星级</div>
    <div class="right6Table d-flex">
      <div class="star">
        <div style="color:#A6E8FF;font-size:16px;">星级</div>
        <div>
          <i class="el-icon-star-on" style="color: #ff9b46;font-size:18px;"></i>
          <i class="el-icon-star-on" style="color: #ff9b46;font-size:18px;"></i>
          <i class="el-icon-star-on" style="color: #ff9b46;font-size:18px;"></i>
        </div>
        <div>
          <i class="el-icon-star-on" style="color: #ff9b46;font-size:18px;"></i>
          <i class="el-icon-star-on" style="color: #ff9b46;font-size:18px;"></i>
        </div>
        <div>
          <i class="el-icon-star-on" style="color: #ff9b46;font-size:18px;"></i>
        </div>
      </div>
      <div class="number">
        <div style="color:#A6E8FF;font-size:16px;">数量</div>
        <div style="color:#ffffff;font-size:20px;">{{targetData[0]}}</div>
        <div style="color:#ffffff;font-size:20px;">{{targetData[1]}}</div>
        <div style="color:#ffffff;font-size:20px;">{{targetData[2]}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { formatTime } from "@/utils/index.js";

export default {
  props: {},
  data() {
    return {
      saleType: 1,
      targetData: [],
    };
  },
  mounted() {
    // this.timer();
    this.init();
    // this.iniChart();
  },
  methods: {
    // 获取图表的数据
    getRechartData() {
      axios
        .post("api/sscreen/order/report", {
          action: "star.stat",
          uuid: this.uuid,
        })
        .then((res) => {
          const {
            data: { data },
          } = res;
          console.log(data);
          this.targetData = data;
        });
    },

    async init() {
      if (window.android) {
        var qs = await JSON.parse(window.android.getBindInfo());
        this.uuid = qs["uuid"];
      } else {
        this.uuid = this.$route.query.uuid;
      }
      this.getRechartData();
    },
  },
};
</script>

<style lang="scss">
#right6 {
  width: 100%;
  color: #ffffff;

  .title {
    height: 32px;
    line-height: 32px;
    padding-left: 16px;
    color: #a6e8ff;
    font-size: 18px;
    background: url("../../../assets/bg_title.png") no-repeat left;
    background-size: 160px 32px;
  }
  .right6Table {
    width: 192px;
    height: 170px;
    background: url("../../../assets/bg06.png") no-repeat;
    background-size: 100% 100%;
    .star {
      width: 80px;
      height: 43px;
      line-height: 43px;
      text-align: center;
      div {
        background: url("../../../assets/bg08.png") no-repeat;
        background-size: 100% 100%;
      }
    }
    .number {
      width: 110px;
      height: 43px;
      line-height: 43px;
      text-align: center;
      div {
        background: url("../../../assets/bg08.png") no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}
</style>
