<template>
  <div id="middle1">
    <div
      class="box-bac"
      style="width: 100%; height: 88px; margin-bottom: 20px; overflow: hide"
    >
      <div class="allData d-flex">
        <div class="list" v-for="(item, index) in targetData" :key="index">
          <div class="title">{{ item.title }}</div>
          <p class="amount">{{ item.amount }}</p>
        </div>
      </div>
    </div>
    <div style="position: relative">
      <img
        :src="
          midimg
            ? midimg
            : 'https://www.chuansmart.com/storage/sscreen/bigScreen_default.png'
        "
        style="width: 100%; height: 480px; margin-bottom: 20px"
        @click="fMarketShow()"
      />
      <div class="market-mask" v-show="marketShow">
        <div class="all_market">
          <div class="list" v-for="(item, index) in marketGoList" :key="index" @click="fGoScreen(item)">
            <img
              src="@/assets/screen_mask_market.png"
              style="width: 24px; height: 24px"
            />
            <span>{{ item.aka }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { formatTime } from "@/utils/index.js";

export default {
  props: {
    midimg: {
      default: "",
      type: String,
    },
  },
  data() {
    return {
      targetData: [],
      marketGoList: [],
      marketShow:false,
    };
  },
  mounted() {
    // this.timer();
    this.init();
    // this.iniChart();
  },
  methods: {
    fMarketShow(){
      this.marketShow=!this.marketShow;
    },
    // 获取图表的数据
    getRechartData() {
      axios
        .post("api/sscreen/order/report", {
          action: "top.stat",
          uuid: this.uuid,
        })
        .then((res) => {
          const {
            data: { data },
          } = res;
          console.log(data);
          this.targetData = [
            { title: "总面积(m²)", amount: data.area },
            { title: "市场数", amount: data.market_num },
            { title: "摊位数", amount: data.orgz_num },
            { title: "日客流量", amount: data.flow },
          ];
        });
    },
    getMarketsList() {
      axios
        .post("api/sscreen/order/report", {
          action: "market.screen.link.get",
          uuid: this.uuid,
        })
        .then((res) => {
          const {
            data: { data },
          } = res;
          console.log(data);
          this.marketGoList = data;
        });
    },
    fGoScreen(list){
      if(list.uuid){
        // window.open('http://screen.chuansmart.com/index1?uuid='+list.uuid+"&isInter=true&iuuid="+this.$route.query.uuid)
        this.$router.push({
        path:
          "/index1?uuid=" +
          list.uuid+"&isInter=true&iuuid="+this.$route.query.uuid
      });
      }else{
        this.$message.error('该市场没有大屏哦！');
      }
    },
    async init() {
      if (window.android) {
        var qs = await JSON.parse(window.android.getBindInfo());
        this.uuid = qs["uuid"];
      } else {
        this.uuid = this.$route.query.uuid;
      }
      this.getRechartData();
      this.getMarketsList();
    },
  },
};
</script>

<style lang="scss">
#middle1 {
  width: 100%;
  //   height: 2.525rem;
  color: #ffffff;
  .box-bac {
    background: url("../../../assets/bg02.png") no-repeat;
    background-size: 100% 100%;
  }
  .allData {
    width: 100%;
    background: url("../../../assets/bg08.png") no-repeat left;
    background-size: 100% 100%;
    .list {
      width: 25%;
      height: 88px;
      text-align: center;
      line-height: 1;
      padding-top: 12px;
      margin-right: 15px;
      background: url("../../../assets/bg08.png") no-repeat left;
      background-size: 100% 100%;
      .title {
        font-size: 20px;
        color: #a6e8ff;
        margin-bottom: 12px;
      }
      .amount {
        font-size: 28px;
        font-weight: bold;
        color: #64ffa3;
      }
    }
    .list:last-child {
      margin-right: 0;
    }
  }
  .market-mask {
    position: absolute;
    top: -12px;
    left: 0;
    width: 290px;
    height: 506px;
    background: rgba(9, 4, 69, 0.7);
    border: 1px solid #3d57e0;
    padding: 13px 14px;
      overflow: hidden;
      overflow-y: scroll;
    .all_market {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .list {
        display: flex;
        justify-content: center;
        width: 122px;
        height: 42px;
        text-align: center;
      align-items: center;
        background: url("https://www.chuansmart.com/storage/screen/screen_mask_btn.png") no-repeat;
        background-size: 100% 100%;
        margin-bottom: 18px;
        font-size: 14px;
      }
    }
  }
}
</style>
