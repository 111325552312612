<template>
  <div id="right2">
    <div class="box-bac"
      style="width: 100%; height: 72px;overflow: hide;margin-bottom: 16px;"
    >
      <div class="allData d-flex">
        <div class="list" v-for="(item, index) in targetData" :key="index">
          <div class="title">
            <i class="el-icon-caret-top red" v-if="index == 3"></i
            >{{ item.title }}
          </div>
          <p class="amount" :class="index == 3 ? 'red' : ''">
            {{ item.amount }}
          </p>
        </div>
      </div>
    </div>
    <div class="box-bac"
      style="width: 100%; height: 72px;overflow: hide"
    >
      <div class="allData d-flex">
        <div class="list" v-for="(item, index) in targetData2" :key="index">
          <div class="title">
            <i class="el-icon-caret-top red" v-if="index == 3"></i
            >{{ item.title }}
          </div>
          <p class="amount" :class="index == 3 ? 'red' : ''">
            {{ item.amount }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { formatTime } from "@/utils/index.js";

export default {
  props: {},
  data() {
    return {
      saleType: 1,
      targetData: [],
      targetData2: [],
    };
  },
  mounted() {
    // this.timer();
    this.init();
  },
  methods: {
    // 获取图表的数据
    getRechartData() {
      axios
        .post("api/sscreen/order/report", {
          action: "item.statistics",
          uuid: this.uuid,
        })
        .then((res) => {
          const {
            data: { data },
          } = res;
          console.log(data);
          this.targetData = [
            {
              title: "监督事项",
              amount: data.item_num,
            },
            {
              title: "已处理数",
              amount: data.already_num,
            },
            {
              title: "处理超时",
              amount: data.already_out_time_num,
            },
            {
              title: "未处理数",
              amount: data.no_dispose_num,
            },
          ];
          this.targetData2 = [
            {
              title: "商户事项",
              amount: data.merchant_item_num,
            },
            {
              title: "已处理数",
              amount: data.merchant_already_num,
            },
            {
              title: "处理超时",
              amount: data.merchant_already_out_time_num,
            },
            {
              title: "未处理数",
              amount: data.merchant_no_dispose_num,
            },
          ];
        });
    },

    async init() {
      if (window.android) {
        var qs = await JSON.parse(window.android.getBindInfo());
        this.uuid = qs["uuid"];
      } else {
        this.uuid = this.$route.query.uuid;
      }
      this.getRechartData();
    },
  },
};
</script>

<style lang="scss">
#right2 {
  width: 100%;
  color: #ffffff;
  .box-bac{
    background: url("../../../assets/bg02.png") no-repeat;
    background-size: 100% 100%;
  }
  .allData {
    width: 100%;
    height: 72px;
    justify-content: space-between;
    background: url("../../../assets/bg08.png") no-repeat left;
    background-size: 100% 100%;
    .list {
      width: 142px;
      height: 72px;
      text-align: center;
      line-height: 1;
      padding-top: 8px;
      background: url("../../../assets/bg06.png") no-repeat left;
      background-size: 100% 100%;
      .title {
        font-size: 20px;
        color: #a6e8ff;
        margin-bottom: 12px;
      }
      .amount {
        font-size: 24px;
        font-weight: bold;
        color: #ffffff;
      }
      .red {
        color: #ff6a28;
      }
    }
  }
}
</style>
