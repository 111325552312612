<template>
  <div id="index22">
    <dv-full-screen-container class="bg22">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div class="d-flex jc-center" style="margin-bottom:24px;">
          <div
            style="
              display: flex;
              justify-content: space-between;
              width: 100%;
              align-items: center;
              padding: 0 0.25rem;
            "
          >
            <div style="width: 8rem; display: flex; align-items: center">
              <span
              v-if="integreteData.is_show_weather == 1"
                style="
                  font-size: 0.3rem;
                  font-family: PingFangSC-Medium, PingFang SC;
                  font-weight: 500;
                  color: #ffffff;
                  margin-right: 0.05rem;
                "
                >{{ integreteData.weather }}</span
              >
            </div>
            <div class="itop_head">{{ integreteData.title }}</div>
            <div class="text" :class="{ weekStyle: $route.query.type == 2 }">
              <span v-if="integreteData.is_show_date == 1">{{ dateYear }} {{ dateWeek }} {{ dateDay }}</span>
            </div>
          </div>
        </div>

        <div class="body-box">
          <!-- 主体部分，分为左中右三部分，左边又分为左上和左下，中间分为中上和中下，右边分为右上右中右下 -->
          <div
            class="content-box d-flex"
            style="width: 100%; justify-content: space-between"
          >
            <div class="leftFirst" style="width: 606px">
              <!-- 左边 -->
              <div>
                <!--销售数据-->
                <left1 :saleData="saleData" />
              </div>
              <div
                class="d-flex jc-center"
                style="
                  justify-content: space-between;
                  width: 606px;
                  margin-top: 8px;
                "
              >
                <!--左下左边-->
                <div style="width: 240px">
                  <!-- 销售趋势 -->
                  <div style="margin-bottom: 16px">
                    <left2 />
                  </div>
                  <!-- 市场实时销售汇总 -->
                  <div style="margin-bottom: 16px">
                    <left3 />
                  </div>
                  <!-- 品类销售占比 -->
                  <div>
                    <left4 />
                  </div>
                </div>
                <!--左下右边-->
                <div style="width: 350px">
                  <!-- 实时交易 -->
                  <div style="margin-bottom: 16px">
                    <left5 />
                  </div>
                  <!-- 商品价格公示 -->
                  <div>
                    <left6 />
                  </div>
                </div>
              </div>
            </div>
            <!-- 中间 -->
            <div class="middleFirst" style="width: 972px">
              <!-- 总面积 - 地图图片 -->
              <div>
                <mid1 :midimg="integreteData.image_url"></mid1>
              </div>
              <!-- 质检合格率 -->
              <div>
                <mid2></mid2>
              </div>
            </div>
            <!-- 右边 -->
            <div class="rightFirst" style="width: 606px">
              <div>
                <!-- 总商户统计数 -->
                <right1></right1>
              </div>
              <div style="margin-top: 16px">
                <!--市场出租数-市场续签率-->
                <right2></right2>
              </div>
              <div style="margin-top: 16px">
                <!--监督事项-已处理数.....数据统计-->
                <right3></right3>
              </div>
              <div
                style="margin-top: 16px;justify-content: space-between;"
                class="d-flex"
              >
                <div style="width: 192px">
                  <!--处理时效趋势-->
                  <right4></right4>
                </div>
                <div style="width: 192px">
                  <!--消费者投诉-->
                  <right5></right5>
                </div>
                <div style="width: 192px">
                  <!--商户星级-->
                  <right6></right6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </dv-full-screen-container>
  </div>
</template>

<script>
import axios from "axios";
import { formatTime } from "@/utils/index.js";
import left1 from "./components/left1";
import left2 from "./components/left2";
import left3 from "./components/left3";
import left4 from "./components/left4";
import left5 from "./components/left5";
import left6 from "./components/left6";
import mid1 from "./components/middle1";
import mid2 from "./components/middle2";
import { getUserIP } from "@/utils/ip";
import right1 from "./components/right1";
import right2 from "./components/right2";
import right3 from "./components/right3";
import right4 from "./components/right4";
import right5 from "./components/right5";
import right6 from "./components/right6";

export default {
  data() {
    return {
      saleData: [
        {
          title: "日销售笔数",
          number: "680492",
          radio: "4%",
        },
        {
          title: "日销售额",
          number: "689382",
          radio: "4%",
        },
        {
          title: "周销售笔数",
          number: "6049382",
          radio: "4%",
        },
        {
          title: "周销售额",
          number: "680492",
          radio: "4%",
        },
        {
          title: "月销售笔数",
          number: "680382",
          radio: "4%",
        },
        {
          title: "月销售额",
          number: "680493",
          radio: "4%",
        },
        {
          title: "年销售笔数",
          number: "49382",
          radio: "4%",
        },
        {
          title: "年销售额",
          number: "69382",
          radio: "4%",
        },
      ],
      merchant_num_show: false,
      sale_money_show: false,
      sale_num_show: false,
      flow_num_show: false,
      mouth_quality_num_show: false,
      inspection_num_show: false,
      qualified_rate_show: false,
      floor_num_show: false,
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      global_data: {}, //全局数据
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      market_name: "",
      uuid: "",
      type: "",
      notice: "",
      sale_money: "",
      sale_num: "",
      merchant_num: "",
      flow_num: "",
      mouth_quality_num: "",
      inspection_num: "",
      qualified_rate: "",
      show: false,
      windowWidth: document.documentElement.clientWidth, //实时屏幕宽度
      windowHeight: document.documentElement.clientHeight, //实时屏幕高度
      ip: "",
      local: {
        // ip地址所在城市
        city: "",
        // 所在城市温度
        temperature: "",
        // 天气类型
        type: "",
        province: "",
      },
      debug_city: "",
      integreteData: {},
      ip: "",
    };
  },
  components: {
    left1,
    left2,
    left3,
    left4,
    left5,
    left6,
    mid1,
    mid2,
    right1,
    right2,
    right3,
    right4,
    right5,
    right6,
  },
  mounted() {
if(!sessionStorage.getItem("ip")){
    this.getIp();
    }else{
      this.init();
    }
    this.timeFn();
    this.cancelLoading();
    setInterval(() => {
      this.$router.go(0);
    }, 300000);
  },
  methods: {
    getIp(){
          const getIPs = (callback) => {
      var ip_dups = {};
      var RTCPeerConnection =
        window.RTCPeerConnection ||
        window.mozRTCPeerConnection ||
        window.webkitRTCPeerConnection;
      var useWebKit = !!window.webkitRTCPeerConnection;
      var mediaConstraints = {
        optional: [{ RtpDataChannels: true }],
      };
      // 这里就是需要的ICEServer了
      var servers = {
        iceServers: [
          { urls: "stun:stun.services.mozilla.com" },
          { urls: "stun:stun.l.google.com:19302" },
        ],
      };
      var pc = new RTCPeerConnection(servers, mediaConstraints);
      function handleCandidate(candidate) {
        var ip_regex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/;
        var hasIp = ip_regex.exec(candidate);
        if (hasIp) {
          var ip_addr = ip_regex.exec(candidate)[1];
          if (ip_dups[ip_addr] === undefined) callback(ip_addr);
          ip_dups[ip_addr] = true;
        }
      }
      // 网络协商的过程
      pc.onicecandidate = function(ice) {
        if (ice.candidate) {
          handleCandidate(ice.candidate.candidate);
        }
      };
      pc.createDataChannel("");
      //创建一个SDP(session description protocol)会话描述协议 是一个纯文本信息 包含了媒体和网络协商的信息
      pc.createOffer(
        function(result) {
          pc.setLocalDescription(
            result,
            function() {},
            function() {}
          );
        },
        function() {}
      );
      setTimeout(function() {
        var lines = pc.localDescription.sdp.split("\n");
        lines.forEach(function(line) {
          if (line.indexOf("a=candidate:") === 0) handleCandidate(line);
        });
      }, 1000);
    };
    let that = this;
    getIPs((ip) => {
      console.log(ip);
      that.ip = ip;
      console.log(that.ip);
      that.init();
    });
    },
    timeFn() {
      setInterval(() => {
        this.dateDay = formatTime(new Date(), "HH: mm: ss");
        this.dateYear = formatTime(new Date(), "yyyy-MM-dd");
        this.dateWeek = this.weekday[new Date().getDay()];
      }, 1000);
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
    async init() {
      if (window.android) {
        var qs = await JSON.parse(window.android.getBindInfo());
        this.uuid = qs["uuid"];
        this.type = qs["type"];
      } else {
        this.uuid = this.$route.query.uuid;
        this.type = this.$route.query.type;
      }
      this.getMarketTitle();
    },
    async topInit() {
      if (window.android) {
        var qs = await JSON.parse(window.android.getBindInfo());
        this.uuid = qs["uuid"];
      } else {
        this.uuid = this.$route.query.uuid;
      }
    },

    getMarketTitle() {
      axios
        .post("api/sscreen/client/main", {
          action: "sscreen.init",
          uuid: this.uuid,
          ip: this.ip,
          // uuid: "f8a9737f-edf7-3fd2-bc61-5318ca8705ff",
        })
        .then((res) => {
          console.log(res);
          let data = res.data;
          if (data.code == 0) {
            this.integreteData = data.data;
            console.log(this.integreteData);
          }
        });
    },
    link() {
      this.show = !this.show;
    },
    changeScreen() {
      this.$router.push({ name: "index" });
    },
    //执行每天24:00 刷新
    refreshWindow(){
        var nowTemp = new Date().getTime() //获取当前时间戳
        var tomorrowTemp = new Date(new Date().toLocaleDateString()).getTime() +24 * 60 * 60 * 1000;
        var residueTemp = tomorrowTemp - nowTemp //距离当天24：00的时间戳
        //执行定时任务
        setTimeout(() => {
          this.nowData();
          
        }, residueTemp)
    }
  },
};
</script>

<style lang="scss">
// @import "@/assets/scss/index1.scss";
@import "../../assets/font/font.css";
.bg22 {
  padding: 0 40px;
  background: #0b054a;
  background-size: cover;
  background-position: center center;
}
.flex-center-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-center-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.host-body {
  background: url("../../assets/bg_top.png") no-repeat;
  background-size: 100%;

  .itop_head {
    line-height: 90px;
    text-align: center;
    width: 100%;
    height: 90px;
    font-size: 48px;
    font-weight: normal;
    font-family: PingFang SC-Semibold;
    font-weight: bold;
    color: #64D6FF;
    letter-spacing: 0.16rem;
  }
  .text {
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    width: 8rem;
    text-align: right;
  }
  .weekStyle {
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    width: 640px;
    text-align: right;
  }
}
</style>
