<template>
  <div id="left5">
    <div class="title">实时交易</div>
    <div style="width: 100%">
      <div class="box-bac" style="width: 100%; height: 288px; overflow: hide">
        <dv-scroll-board
          :config="config"
          v-if="targetData.length > 0"
          style="width: 350px; height: 288px; margin: 0 auto"
        />
        <div v-else class="noData" style="width: 350px; height: 288px"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { formatTime } from "@/utils/index.js";

export default {
  data() {
    return {
      config: {},
      targetData: [],
    };
  },
  mounted() {
    // this.timer();
    this.init();
    // this.getList();
  },
  methods: {
    // 获取表格的数据
    getTableList() {
      axios
        .post("api/sscreen/order/report", {
          action: "product.sale",
          uuid: this.uuid,
        })
        .then((res) => {
          const {
            data: { data },
          } = res;
          console.log(data);
          if (data.length > 0) {
            const target = data.map((c) => {
              return {
              market_name:'<span class="'+(c.market_name.length>7 ? 'twoline' : '') +'">'+c.market_name+'</span>',
              product_name:'<span class="'+(c.product_name.length>7 ? 'twoline' : '') +'">'+c.product_name+'</span>',
                deal_price:c.deal_price ? (c.deal_price / 100).toFixed(2) : 0,
                sale_quantity: c.sale_quantity + c.unit_name,
                deal_amount:
                  "￥" + (c.deal_amount ? (c.deal_amount / 100).toFixed(2) : 0),
              };
            });
            this.targetData = target;
            this.config.data = target;
            let tabHead = ["市场名称", "商品", "单价", "数量", "小计"];
            this.config = {
              data: target,
              header: tabHead,
              rowNum: 6, //表格行数
              headerHeight: 46,
              headerBGC: "rgb(4, 7, 138, 0)", //表头
              oddRowBGC: "rgba(50, 46, 173, 0)", //奇数行
              evenRowBGC: "rgba(50, 46, 173, 0.09)", //偶数行
              align: ["center", "center", "center", "center", "center"],
            };
          }
        });
    },
    // 字体适配
    FontChart(res) {
      //获取到屏幕的宽度
      var clientWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (!clientWidth) return; //报错拦截：
      let fontSize = 80 * (clientWidth / 1920);
      console.log(fontSize);
      return res * fontSize;
    },
    timer() {
      return setInterval(() => {
        this.getList();
      }, 60000);
    },
    async init() {
      if (window.android) {
        var qs = await JSON.parse(window.android.getBindInfo());
        this.uuid = qs["uuid"];
      } else {
        this.uuid = this.$route.query.uuid;
      }
      this.getTableList();
    },
  },
};
</script>

<style lang="scss">
#left5 {
  width: 350px;
  height: 320px;
  color: #ffffff;
  .box-bac {
    background: url("../../../assets/bg06.png") no-repeat;
    background-size: 100% 100%;
  }
  .title {
    height: 32px;
    line-height: 32px;
    padding-left: 16px;
    color: #a6e8ff;
    font-size: 18px;
    background: url("../../../assets/bg_title.png") no-repeat left;
    background-size: 160px 32px;
  }
  .ms_title {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    height: 48px;
    line-height: 48px;
    padding-left: 8px;
  }

  .ms_title::before {
    content: " ";
    display: inline-block;
    width: 4px;
    height: 12px;
    margin-right: 8px;
    background-color: #64ffa3;
  }

  .bg-color-black {
    // overflow: hidden;
    height: 208px;
  }
  .dv-scroll-board{
     .header {
    justify-content: space-between;
    border: 1px solid #4a60fb !important;
    background-color: rgba(4, 7, 138, 0) !important;
    color: #64d6ff;
    .header-item {
      width: 20%;
      padding: 0;
    }
  }
  }
  .dv-scroll-board .rows{
    height: 235px !important;
    padding-left: 6px;
    padding-right: 6px;
    .row-item {
    justify-content: space-between;
    .ceil {
      width: 20%;
      text-align: center;
      padding: 0;
    }
  } 
  }
  .text {
    height: 24px;
    line-height: 24px;
    color: #c3cbde;
  }
  .body-box {
    overflow: hidden;
  }
  .noData {
    background: url("../../../assets/default.png") no-repeat center center;
    background-size: 180px 100px;
  }
  .twoline {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: -webkit-box !important;
    -webkit-box-orient: vertical !important;
    -webkit-line-clamp: 2 !important;
    white-space: normal !important;
    line-height: normal !important;
  }
}
</style>
