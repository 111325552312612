<template>
  <div id="ileft1" class="flex-center-start">
    <div class="list flex-center-between">
      <div>
        <p style="font-size:18px;color:#A6E8FF;margin-bottom: 6px;">日销售笔数</p>
        <p style="font-size:24px;">{{shuData.daily_sale?shuData.daily_sale:0}}</p>
      </div>
      <div>
        <p style="font-size:18px;color:#A6E8FF;margin-bottom: 6px;text-align:right;padding-right: 20px;">环比</p>
        <p class="d-flex" style="font-size:24px;color:#64FFA3;">{{shuData.daily_sale_radio?shuData.daily_sale_radio:0}}%
          <img v-if="shuData.daily_sale_positive == 1" src="@/assets/up.png" style="width:20px;height:20px;margin-top: 5px;"/>
          <img v-if="shuData.daily_sale_positive == 0" src="@/assets/down.png" style="width:20px;height:20px;margin-top: 5px;"/></p>
      </div>
    </div>
    <div class="list flex-center-between">
      <div>
        <p style="font-size:18px;color:#A6E8FF;margin-bottom: 6px;">日销售额</p>
        <p style="font-size:24px;">{{shuData.daily_amount?shuData.daily_amount:0}}</p>
      </div>
      <div>
        <p style="font-size:18px;color:#A6E8FF;margin-bottom: 6px;text-align:right;padding-right: 20px;">环比</p>
        <p class="d-flex" style="font-size:24px;color:#64FFA3;">{{shuData.daily_amount_radio?shuData.daily_amount_radio:0}}%
          <img v-if="shuData.daily_amount_positive == 1" src="@/assets/up.png" style="width:20px;height:20px;margin-top: 5px;"/>
          <img v-if="shuData.daily_amount_positive == 0" src="@/assets/down.png" style="width:20px;height:20px;margin-top: 5px;"/>
        </p>
      </div>
    </div>
    <div class="list flex-center-between">
      <div>
        <p style="font-size:18px;color:#A6E8FF;margin-bottom: 6px;">周销售笔数</p>
        <p style="font-size:24px;">{{shuData.weeky_sale?shuData.weeky_sale:0}}</p>
      </div>
      <div>
        <p style="font-size:18px;color:#A6E8FF;margin-bottom: 6px;text-align:right;padding-right: 20px;">环比</p>
        <p class="d-flex" style="font-size:24px;color:#64FFA3;">{{shuData.weeky_sale_radio?shuData.weeky_sale_radio:0}}%
          <img v-if="shuData.weeky_sale_positive == 1" src="@/assets/up.png" style="width:20px;height:20px;margin-top: 5px;"/>
          <img v-if="shuData.weeky_sale_positive == 0" src="@/assets/down.png" style="width:20px;height:20px;margin-top: 5px;"/>
        </p>
      </div>
    </div>
    <div class="list flex-center-between">
      <div>
        <p style="font-size:18px;color:#A6E8FF;margin-bottom: 6px;">周销售额</p>
        <p style="font-size:24px;">{{shuData.weeky_amount?shuData.weeky_amount:0}}</p>
      </div>
      <div>
        <p style="font-size:18px;color:#A6E8FF;margin-bottom: 6px;text-align:right;padding-right: 20px;">环比</p>
        <p class="d-flex" style="font-size:24px;color:#64FFA3;">{{shuData.weeky_amount_radio?shuData.weeky_amount_radio:0}}%
          <img v-if="shuData.weeky_amount_positive == 1" src="@/assets/up.png" style="width:20px;height:20px;margin-top: 5px;"/>
          <img v-if="shuData.weeky_amount_positive == 0" src="@/assets/down.png" style="width:20px;height:20px;margin-top: 5px;"/>
        </p>
      </div>
    </div>
    <div class="list flex-center-between">
      <div>
        <p style="font-size:18px;color:#A6E8FF;margin-bottom: 6px;">月销售笔数</p>
        <p style="font-size:24px;">{{shuData.month_sale?shuData.month_sale:0}}</p>
      </div>
      <div>
        <p style="font-size:18px;color:#A6E8FF;margin-bottom: 6px;text-align:right;padding-right: 20px;">环比</p>
        <p class="d-flex" style="font-size:24px;color:#64FFA3;">{{shuData.month_sale_radio?shuData.month_sale_radio:0}}%
          <img v-if="shuData.month_sale_positive == 1" src="@/assets/up.png" style="width:20px;height:20px;margin-top: 5px;"/>
          <img v-if="shuData.month_sale_positive == 0" src="@/assets/down.png" style="width:20px;height:20px;margin-top: 5px;"/>
        </p>
      </div>
    </div>
    <div class="list flex-center-between">
      <div>
        <p style="font-size:18px;color:#A6E8FF;margin-bottom: 6px;">月销售额</p>
        <p style="font-size:24px;">{{shuData.month_amount?shuData.month_amount:0}}</p>
      </div>
      <div>
        <p style="font-size:18px;color:#A6E8FF;margin-bottom: 6px;text-align:right;padding-right: 20px;">环比</p>
        <p class="d-flex" style="font-size:24px;color:#64FFA3;">{{shuData.month_amount_radio?shuData.month_amount_radio:0}}%
          <img v-if="shuData.month_amount_positive == 1" src="@/assets/up.png" style="width:20px;height:20px;margin-top: 5px;"/>
          <img v-if="shuData.month_amount_positive == 0" src="@/assets/down.png" style="width:20px;height:20px;margin-top: 5px;"/>
        </p>
      </div>
    </div>
    <div class="list flex-center-between">
      <div>
        <p style="font-size:18px;color:#A6E8FF;margin-bottom: 6px;">年销售笔数</p>
        <p style="font-size:24px;">{{shuData.year_sale?shuData.year_sale:0}}</p>
      </div>
      <div>
        <p style="font-size:18px;color:#A6E8FF;margin-bottom: 6px;text-align:right;padding-right: 20px;">环比</p>
        <p class="d-flex" style="font-size:24px;color:#64FFA3;">{{shuData.year_sale_radio?shuData.year_sale_radio:0}}%
          <img v-if="shuData.year_sale_positive == 1" src="@/assets/up.png" style="width:20px;height:20px;margin-top: 5px;"/>
          <img v-if="shuData.year_sale_positive == 0" src="@/assets/down.png" style="width:20px;height:20px;margin-top: 5px;"/>
        </p>
      </div>
    </div>
    <div class="list flex-center-between">
      <div>
        <p style="font-size:18px;color:#A6E8FF;margin-bottom: 6px;">年销售额</p>
        <p style="font-size:24px;">{{shuData.year_amount?shuData.year_amount:0}}</p>
      </div>
      <div>
        <p style="font-size:18px;color:#A6E8FF;margin-bottom: 6px;text-align:right;padding-right: 20px;">环比</p>
        <p class="d-flex" style="font-size:24px;color:#64FFA3;">{{shuData.year_amount_radio?shuData.year_amount_radio:0}}%
          <img v-if="shuData.year_amount_positive == 1" src="@/assets/up.png" style="width:20px;height:20px;margin-top: 5px;"/>
          <img v-if="shuData.year_amount_positive == 0" src="@/assets/down.png" style="width:20px;height:20px;margin-top: 5px;"/>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { formatTime } from "@/utils/index.js";

export default {
  props: {
      saleData:{
          default: [],
      type: Array,
      }
  },
  data() {
    return {
      config: {},
      uuid: "",
      shuData:{},
    };
  },
  mounted() {
    // this.timer();
    this.init();
    // this.getList();
  },
  methods: {
    getList() {
      axios
        .post("api/sscreen/order/report", {
          action: "sale.stat",
          uuid:this.uuid
        })
        .then((res) => {
          const {
            data: { data },
          } = res;
          console.log(data);
          data.daily_amount=data.daily_amount>0?(data.daily_amount/100).toFixed(2):0;
          data.weeky_amount=data.weeky_amount>0?(data.weeky_amount/100).toFixed(2):0;
          data.month_amount=data.month_amount>0?(data.month_amount/100).toFixed(2):0;
          data.year_amount=data.year_amount>0?(data.year_amount/100).toFixed(2):0;
          this.shuData=data;
        });
    },
    // 字体适配
    FontChart(res) {
      //获取到屏幕的宽度
      var clientWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (!clientWidth) return; //报错拦截：
      let fontSize = 80 * (clientWidth / 1920);
      console.log(fontSize);
      return res * fontSize;
    },
    timer() {
      return setInterval(() => {
        this.getList();
      }, 60000);
    },
    async init() {
      if (window.android) {
        var qs = await JSON.parse(window.android.getBindInfo());
        this.uuid = qs["uuid"];
      } else {
        this.uuid = this.$route.query.uuid;
      }
      this.getList();
    },
  },
};
</script>

<style lang="scss">
#ileft1 {
  color: #ffffff;
  flex-wrap: wrap;
  .list {
      width: 300px;
      height: 64px;
      line-height: 1;
      padding: 0 0.2rem;
      margin-bottom: 6px;
      margin-right: 6px;
      background: url("../../../assets/bg02.png") no-repeat left;
    background-size: 100% 100%;
    p {
      color: #ffffff;
    }
  }
  .list:nth-child(even){
      margin-right: 0;
  }
}
</style>
