<template>
  <div id="iright3">
    <div class="title">处理时效趋势</div>
    <div class="box-bac"
      style="width: 100%; height: 172px;overflow: hide"
    >
      <div id="right3Chart" style="width: 100%; height: 172px" v-if="targetData.length>0"></div>
        <div v-else class="noData" style="width: 100%; height: 172px"></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { formatTime } from "@/utils/index.js";

export default {
  props: {},
  data() {
    return {
      saleType: 1,
      targetData: [],
    };
  },
  mounted() {
    // this.timer();
    this.init();
    // this.iniChart();
  },
  methods: {
    // 获取图表的数据
    getRechartData() {
      axios
        .post("api/sscreen/order/report", {
          action: "item.rate",
          uuid: this.uuid,
        })
        .then((res) => {
          const {
            data: { data },
          } = res;
          console.log(data);
          this.targetData = data;
          if(this.targetData.length>0){
          let that = this;
            let times = setTimeout(() => {
              that.iniChart();
              clearTimeout(times)
            }, 1000);
          }
        });
    },
    iniChart() {
      this.chart = this.$echarts.init(document.getElementById("right3Chart"));
      let name=this.targetData.map(ele=>ele.time);
      let numbers=this.targetData.map(ele=>ele.num);
      this.chart.setOption({
        color: ["#80FFA5", "#00DDFF", "#37A2FF", "#FF0087", "#FFBF00"],
        title: {
          show: false,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        legend: {
          show: false,
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "6%",
          top:'8%',
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: name,
            axisLine: {
              lineStyle: {
                color: '#B3EDFF',
                opacity:0.1
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLine: {
              lineStyle: {
                color: '#B3EDFF',
                opacity:0.1
              },
            },
            axisTick:{
              show:false,
            },
            nameTextStyle:{
              color:'#FFFFFF'
            },
            splitLine: {
              show: false,
            },
            axisLabel: {
              formatter: function (value) {
                return value + 'h';
              },
            },
          },
        ],
        series: [
          {
            name: "",
            type: "line",
            stack: "Total",
            smooth: true,
            lineStyle: {
              width: 0,
            },
            showSymbol: false,
            lineStyle:{
                color:'#D455FF'
            },
            areaStyle: {
              opacity: 0.5,
              color: {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [{
                            offset: 0, color: 'rgba(178, 10, 255, 0.50)' // 0% 处的颜色
                        }, {
                            offset: 1, color: 'rgba(55, 0, 255, 0)' // 100% 处的颜色
                        }],
                        global: false
                    },
            },
            emphasis: {
              focus: "series",
            },
            data: numbers,
          },
        ],
      });
    },
    async init() {
      if (window.android) {
        var qs = await JSON.parse(window.android.getBindInfo());
        this.uuid = qs["uuid"];
      } else {
        this.uuid = this.$route.query.uuid;
      }
      this.getRechartData();
    },
  },
};
</script>

<style lang="scss">
#iright3 {
  width: 100%;
  color: #ffffff;
  .box-bac{
    background: url("../../../assets/bg06.png") no-repeat;
    background-size: 100% 100%;
  }
  .title {
    height: 32px;
    line-height: 32px;
    padding-left: 16px;
    color: #a6e8ff;
    font-size: 18px;
    background: url("../../../assets/bg_title.png") no-repeat left;
    background-size: 160px 32px;
  }
  .allData {
    width: 100%;
    padding-top: 12px;
    .list {
      width: 25%;
      text-align: center;
      line-height: 1;
      .title {
        font-size: 20px;
        color: #a6e8ff;
        margin-bottom: 12px;
      }
      .amount {
        font-size: 28px;
        font-weight: bold;
        color: #64ffa3;
      }
    }
  }
  .noData {
    background: url("../../../assets/default.png") no-repeat center center;
    background-size: 180px 100px;
  }
}
</style>
