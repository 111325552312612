<template>
  <div id="left2">
    <div class="title">销售趋势</div>
    <div class="box-bac" style="width: 100%; height: 170px; overflow: hide">
      <div class="changeTime">
        <el-radio-group v-model="saleType" @input="changeTime">
          <el-radio :label="1" style="color: #ffffff">日</el-radio>
          <el-radio :label="2" style="color: #ffffff">周</el-radio>
          <el-radio :label="3" style="color: #ffffff">月</el-radio>
          <el-radio :label="4" style="color: #ffffff">年</el-radio>
        </el-radio-group>
      </div>
      <div
        id="left1Echart"
        style="width: 100%; height: 160px"
        v-if="targetData.length > 0"
      ></div>
      <div
        v-if="targetData.length == 0"
        class="noData"
        style="width: 100%; height: 160px"
      ></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { formatTime } from "@/utils/index.js";

export default {
  props: {},
  data() {
    return {
      saleType: 1,
      targetData: [],
    };
  },
  mounted() {
    // this.timer();
    this.init();
    // this.iniChart();
  },
  methods: {
    changeTime(event) {
      console.log(event);
      console.log(this.saleType);
      this.getRechartData();
    },
    // 获取图表的数据
    getRechartData() {
      axios
        .post("api/sscreen/order/report", {
          action: "sale.trend",
          uuid: this.uuid,
          type: this.saleType,
        })
        .then((res) => {
          const {
            data: { data },
          } = res;
          console.log(data);
          this.targetData = data.map((c) => {
            const { amount, abscissa, unit } = c;
            return {
              name:
                this.saleType == 1
                  ? abscissa + ":00"
                  : this.saleType == 2
                  ? "周" + abscissa
                  : this.saleType == 3
                  ? abscissa + "日"
                  : abscissa + "月",
              value: amount,
              unit: unit,
            };
          });
          if (this.targetData.length > 0) {
            let that = this;
            let times = setTimeout(() => {
              that.iniChart();
              clearTimeout(times);
            }, 1000);
          }
        });
    },

    iniChart() {
      this.chart = this.$echarts.init(document.getElementById("left1Echart"));
      let unit = this.targetData[0].unit;
      console.log(unit);
      this.chart.setOption({
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "#042026",
          axisPointer: {
            type: "shadow",
            label: {
              show: true,
              backgroundColor: "#7B7DDC",
            },
          },
        },
        legend: {
          show: false,
        },
        grid: {
          x: "18%",
          width: "82%",
          y: "10%",
        },
        xAxis: {
          data: this.targetData.map((ele) => ele.name),
          axisLine: {
            lineStyle: {
              color: "#B4B4B4",
            },
          },
          axisLabel: {
            interval: 0,
            rotate: 40,
          },
        },
        yAxis: [
          {
            splitLine: { show: false },
            axisLine: {
              show: false,
              lineStyle: {
                color: "#B4B4B4",
              },
            },
            axisLabel: {
              formatter: function (value) {
                return value + unit;
              },
            },
          },
        ],
        series: [
          {
            name: "销售额",
            type: "line",
            barGap: "-100%",
            barWidth: 5,
            symbol: "none",
            smooth: true,
            itemStyle: {
              normal: {
                barBorderRadius: 5,
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#FF8400" },
                  { offset: 0.2, color: "#FF8400" },
                  { offset: 1, color: "#FF8400" },
                ]),
              },
            },
            areaStyle: {
              opacity: 0.8,
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(255, 170, 170, 1)",
                },
                {
                  offset: 0.6,
                  color: "rgba(255, 170, 170, 0.50)",
                },
                {
                  offset: 1,
                  color: "rgba(255, 170, 170, 0.10)",
                },
              ]),
            },
            z: -12,
            data: this.targetData.map((ele) => ele.value),
          },
        ],
      });
    },
    async init() {
      if (window.android) {
        var qs = await JSON.parse(window.android.getBindInfo());
        this.uuid = qs["uuid"];
      } else {
        this.uuid = this.$route.query.uuid;
      }
      this.getRechartData();
    },
  },
};
</script>

<style lang="scss">
#left2 {
  width: 240px;
  height: 202px;
  color: #ffffff;
  .box-bac {
    background: url("../../../assets/bg06.png") no-repeat;
    background-size: 100% 100%;
  }
  .title {
    height: 32px;
    line-height: 32px;
    padding-left: 16px;
    color: #a6e8ff;
    font-size: 18px;
    background: url("../../../assets/bg_title.png") no-repeat left;
    background-size: 160px 32px;
  }
  .changeTime {
    text-align: right;
    padding-top: 8px;
    padding-right: 8px;
    .el-radio {
      margin-right: 5px;
    }
  }
  .el-radio__input.is-checked .el-radio__inner {
    border-color: #64ffa3;
    background: #64ffa3;
  }
  .el-radio__input.is-checked + .el-radio__label {
    color: #64ffa3;
  }
  .noData {
    background: url("../../../assets/default.png") no-repeat 26px center;
    background-size: 180px 100px;
  }
}
</style>
