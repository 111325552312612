<template>
  <div id="middle2">
    <div class="box-bac2"
      style="width: 100%; height: 56px; margin-bottom: 20px; overflow: hide"
    >
      <div class="allData d-flex">
        <div
          class="list d-flex"
          v-for="(item, index) in targetData"
          :key="index"
        >
          <div class="title">{{ item.title }}</div>
          <p class="amount">{{ item.amount }}</p>
        </div>
      </div>
    </div>
    <div class="d-flex" style="width: 100%; justify-content: space-between">
      <div class="quality" style="width: 360px">
        <div class="title">质检项目占比</div>
        <div class="box-bac" style="width: 360px; height: 208px; overflow: hide">
          <div id="qualityChart" style="width: 360px; height: 208px" v-if="target.length>0"></div>
        <div v-else class="noData" style="width: 360px; height: 208px"></div>
        </div>
      </div>
      <div class="checkTable">
        <div class="box-bac" style="width: 598px; height: 240px; overflow: hide">
          <dv-scroll-board
            :config="config"
             v-if="targetData2.length>0"
            style="width: 598px; height: 240px; margin: 0 auto"
          />
          <div v-else class="noData" style="width: 598px; height: 240px"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { formatTime } from "@/utils/index.js";

export default {
  props: {},
  data() {
    return {
      saleType: 1,
      targetData: [],
      targetData2: [],
      config: {},
      target: [],
    };
  },
  mounted() {
    // this.timer();
    this.init();
    // this.iniChart();
  },
  methods: {
    getList() {
      axios
        .post("api/sscreen/order/report", {
          action: "quality.statistics",
          uuid: this.uuid,
        })
        .then((res) => {
          const {
            data: { data },
          } = res;
          console.log(data);
          this.targetData = [
            {
              title: "质检次数",
              amount: data.quality_count,
            },
            {
              title: "合格",
              amount: data.success_count,
            },
            {
              title: "不合格",
              amount: data.fail_count,
            },
            {
              title: "合格率",
              amount: data.success_rate + "%",
            },
          ];
        });
    },
    // 获取图表的数据
    getRechartData() {
      axios
        .post("api/sscreen/order/report", {
          action: "quality.cate",
          uuid: this.uuid,
        })
        .then((res) => {
          const {
            data: { data },
          } = res;
          console.log(data);
            this.target = data.map((c) => {
            const { num, name } = c;
            return {
              name: name.length>6?name.slice(0,6)+'...':name,
              value: num,
            };
          });
          if(this.target.length>0){
          let that = this;
            let times = setTimeout(() => {
              that.iniChart();
              clearTimeout(times)
            }, 1000);
          }
        });
    },
    iniChart() {
      const isFont = this.$route.query.type;
      this.chart = this.$echarts.init(document.getElementById("qualityChart"));
      this.chart.setOption({
        color: [ "#4CC529", "#FFF169", "#FFAF44","#EA6B37","#BB2323","#1DA0FF","#1B4DFF","#6A59FF","#C638FF","#E679C8","#52F0E3"],
        tooltip: {
          trigger: "item",
        },

        series: [
          {
            type: "pie",
            radius: ["80%", "55%"],
            data: this.target,
            label: {
              show: true,
              normal: {
                show: true,
                textStyle: {
                  fontSize: this.FontChart(0.12),
                  color: "#ffffff",
                  fontWeight: 400,
                },
                formatter: "{b}",
              },
              x: 0,
            },
            labelLine: {
              show: true,
              length: 20,
            },
          },
        ],
      });
    },
    // 获取图表的数据
    getTableData() {
      axios
        .post("api/sscreen/order/report", {
          action: "quality.list",
          uuid: this.uuid,
          length:200,
          start:0,
        })
        .then((res) => {
          const {
            data: { data },
          } = res;
          console.log(data);
          if(data.length>0){
          let target = data.map(ele=>{
            return{
              orgz_name:'<span class="'+(ele.orgz_name.length>7 ? 'twoline' : '') +'">'+ele.orgz_name+'</span>',
              market_name:'<span class="'+(ele.market_name.length>7 ? 'twoline' : '') +'">'+ele.market_name+'</span>',
              product_name:'<span class="'+(ele.product_name.length>7 ? 'twoline' : '') +'">'+ele.product_name+'</span>',
              cate_name:ele.cate_name,
              status_name:'<span style="color:'+(ele.status==1 ? '#55FFA7':'#FD3131')+'">'+(ele.status==1?'合格':'不合格')+'</span>',
            }
          });
          console.log(target)
          this.targetData2=target;
          let tabHead = ["摊位名", "市场名称", "品名", "检查项", "检查结果"];
          this.config = {
            data: target,
            header: tabHead,
            rowNum: 5, //表格行数
            headerHeight: 40,
            headerBGC: "rgb(4, 7, 138, 0)", //表头
            oddRowBGC: "rgba(50, 46, 173, 0)", //奇数行
            evenRowBGC: "rgba(50, 46, 173, 0.09)", //偶数行
            align: ["center", "center", "center", "center", "center"],
          };
          }
        });
    },
    // 字体适配
    FontChart(res) {
      //获取到屏幕的宽度
      var clientWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (!clientWidth) return; //报错拦截：
      let fontSize = 80 * (clientWidth / 1920);
      console.log(fontSize);
      return res * fontSize;
    },
    async init() {
      if (window.android) {
        var qs = await JSON.parse(window.android.getBindInfo());
        this.uuid = qs["uuid"];
      } else {
        this.uuid = this.$route.query.uuid;
      }
      this.getList();
      this.getRechartData();
      this.getTableData();
    },
  },
};
</script>

<style lang="scss">
#middle2 {
  width: 100%;
  //   height: 2.525rem;
  color: #ffffff;
  .box-bac{
    background: url("../../../assets/bg06.png") no-repeat;
    background-size: 100% 100%;
  }
  .box-bac2{
    background: url("../../../assets/bg02.png") no-repeat;
    background-size: 100% 100%;
  }
  .allData {
    width: 100%;
    background: url("../../../assets/bg08.png") no-repeat left;
    background-size: 100% 100%;
    .list {
      width: 25%;
      text-align: center;
      line-height: 56px;
      margin-right: 16px;
      justify-content: space-between;
      padding: 0 15px;
      background: url("../../../assets/bg02.png") no-repeat left;
    background-size: 100% 100%;
      .title {
        font-size: 20px;
        color: #a6e8ff;
      }
      .amount {
        font-size: 24px;
        font-weight: bold;
        color: #ffffff;
      }
    }
    .list:nth-child(3) {
      .amount {
        color: red;
        font-size: 26px;
      }
    }
    .list:last-child {
      margin-right: 0;
    }
  }
  .quality {
    .title {
      height: 32px;
      line-height: 32px;
      padding-left: 16px;
      color: #a6e8ff;
      font-size: 18px;
      background: url("../../../assets/bg_title.png") no-repeat left;
      background-size: 160px 32px;
    }
  }
  .checkTable {
    .ms_title {
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      height: 48px;
      line-height: 48px;
      padding-left: 8px;
    }

    .ms_title::before {
      content: " ";
      display: inline-block;
      width: 4px;
      height: 12px;
      margin-right: 8px;
      background-color: #64ffa3;
    }

    .bg-color-black {
      // overflow: hidden;
      height: 208px;
    }
    .dv-scroll-board .header {
    justify-content: space-between;
      border: 1px solid #4a60fb !important;
    background-color: rgba(4, 7, 138, 0) !important;
      color: #64d6ff;
      .header-item{
        width: 20%;
        padding: 0 5px;
      }
    }
  .dv-scroll-board .rows .row-item{
    justify-content: space-between;
    .ceil{
        width: 20%;
        text-align: center;
        padding: 0 5px;
    }
  }
    .text {
      height: 24px;
      line-height: 24px;
      color: #c3cbde;
    }
    .body-box {
      overflow: hidden;
    }
  }
  .noData {
    background: url("../../../assets/default.png") no-repeat center center;
    background-size: 180px 100px;
  }
  .twoline {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: -webkit-box !important;
    -webkit-box-orient: vertical !important;
    -webkit-line-clamp: 2 !important;
    white-space: normal !important;
    line-height: normal !important;
  }
}
</style>
