<template>
  <div id="left4">
    <div class="title">品类销售占比</div>
    <div style="width: 100%">
      <div class="box-bac" style="width: 100%;height:170px;overflow:hide;">
        <div id="left4Echart" style="width: 100%; height: 168px" v-if="targetData.length>0"></div>
        <div v-else class="noData" style="width: 100%; height: 168px"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { formatTime } from "@/utils/index.js";

export default {
  props: {},
  data() {
    return {
      targetData: [],
    };
  },
  mounted() {
    // this.timer();
    this.init();
    // this.iniChart();
  },
  methods: {
    // 获取图表的数据
    getRechartData() {
      axios
        .post("api/sscreen/order/report", {
          action: "product.cate",
          uuid: this.uuid,
        })
        .then((res) => {
          const {
            data: { data },
          } = res;
          console.log(data);
          this.targetData = data.map((c) => {
            const { cate_name, rate } = c;
            return {
              name: cate_name.length>2?(cate_name.slice(0,2)+'...'):cate_name,
              value: rate,
            };
          });
          if(this.targetData.length>0){
          let that = this;
            let times = setTimeout(() => {
              that.iniChart();
              clearTimeout(times)
            }, 1000);
          }
        });
    },

    iniChart() {
      const isFont = this.$route.query.type;
      this.chart = this.$echarts.init(document.getElementById("left4Echart"));
      this.chart.setOption({
        color: [ "#4CC529", "#FFF169", "#FFAF44","#EA6B37","#BB2323","#1DA0FF","#1B4DFF","#6A59FF","#C638FF","#E679C8","#52F0E3"],
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            type: "pie",
            data: this.targetData,
            center: ['50%', '50%'],
            radius: '50%', //饼图的半径大小  
            label: {
              normal: {
                textStyle: {
                  fontSize:this.FontChart(0.10),
                  color: "#ffffff",
                  fontWeight: 400,
                },
                formatter: "{b} \n{c}%  ",
              },
              x: 0,
            },
            labelLine: {
              length: 6,
            },
          },
        ],
      });
    },
    // 字体适配
    FontChart(res) {
      //获取到屏幕的宽度
      var clientWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (!clientWidth) return; //报错拦截：
      let fontSize = 80 * (clientWidth / 1920);
      console.log(fontSize);
      return res * fontSize;
    },
    timer() {
      return setInterval(() => {
        this.getList();
      }, 60000);
    },
    async init() {
      if (window.android) {
        var qs = await JSON.parse(window.android.getBindInfo());
        this.uuid = qs["uuid"];
      } else {
        this.uuid = this.$route.query.uuid;
      }
      this.getRechartData();
    },
  },
};
</script>

<style lang="scss">
#left4 {
  width: 240px;
  height: 202px;
  color: #ffffff;
  .box-bac{
    background: url("../../../assets/bg06.png") no-repeat;
    background-size: 100% 100%;
  }
  .title {
    height: 32px;
    line-height: 32px;
    padding-left: 16px;
    color: #a6e8ff;
    font-size: 18px;
    background: url("../../../assets/bg_title.png") no-repeat left;
    background-size: 160px 32px;
  }
  .noData {
    background: url("../../../assets/default.png") no-repeat 26px center;
    background-size: 180px 100px;
  }
}
</style>
