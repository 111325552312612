<template>
  <div id="right5">
    <div class="title">消费者投诉</div>
    <div class="box-bac">
      <div
        id="right5Chart"
        style="width: 192px; height: 169px"
        v-if="targetData.length > 0"
      ></div>
      <div v-else class="noData" style="width: 192px; height: 169px"></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { formatTime } from "@/utils/index.js";

export default {
  props: {},
  data() {
    return {
      saleType: 1,
      targetData: [],
    };
  },
  mounted() {
    // this.timer();
    this.init();
    // this.iniChart();
  },
  methods: {
    // 获取图表的数据
    getRechartData() {
      axios
        .post("api/sscreen/order/report", {
          action: "complaint.stat",
          uuid: this.uuid,
        })
        .then((res) => {
          const {
            data: { data },
          } = res;
          console.log(data);
          if (data.length > 0) {
            this.targetData = data.map((c) => {
              const { type_name, rate } = c;
              return {
                name: type_name,
                value: rate,
              };
            });
            let that = this;
            let times = setTimeout(() => {
              that.iniChart();
              clearTimeout(times);
            }, 1000);
          }
        });
    },
    iniChart() {
      const isFont = this.$route.query.type;
      //放大值
      //   var showData = [];var pieData = this.targetData.slice(0,7)
      //   var sum = 0;var max = 0;
      //   pieData.forEach((item) => {
      //     sum += item.value;
      //     if (item.value >= max) max = item.value;
      //   });
      // 放大规则
      //   var number = Math.round(max * 0.9);
      //   showData = pieData.map((item) => {
      //     return {
      //       value: number + item.value,
      //       name: item.name,
      //     };
      //   });
      let showData = this.targetData;
      this.chart = this.$echarts.init(document.getElementById("right5Chart"));
      this.chart.setOption({
        color: [
          "#10CFE7",
          "#68D9B5",
          "#FBA1AB",
          "#DAB0FF",
          "#8E6FFF ",
          "#6C44FF",
          "#FE7474",
        ],
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            name: "访问来源",
            type: "pie",
            center: ["50%", "50%"],
            radius: [0, 50],
            roseType: "radius",
            itemStyle: {
              borderRadius: 5,
            },
            data: showData,
            labelLine: {
              show: true,
              length: 0,
            },
            label: {
              normal: {
                formatter: "{b}\n {c}% ",
                textStyle: {
                  fontSize: this.FontChart(0.12),
                  color: "#ffffff",
                  fontWeight: 400,
                },
              },
              x: 0,
            },
          },
        ],
      });
    },
    // 字体适配
    FontChart(res) {
      //获取到屏幕的宽度
      var clientWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (!clientWidth) return; //报错拦截：
      let fontSize = 80 * (clientWidth / 1920);
      console.log(fontSize);
      return res * fontSize;
    },
    async init() {
      if (window.android) {
        var qs = await JSON.parse(window.android.getBindInfo());
        this.uuid = qs["uuid"];
      } else {
        this.uuid = this.$route.query.uuid;
      }
      this.getRechartData();
    },
  },
};
</script>

<style lang="scss">
#right5 {
  width: 100%;
  color: #ffffff;
  justify-content: space-between;
  .box-bac {
    background: url("../../../assets/bg06.png") no-repeat;
    background-size: 100% 100%;
  }
  .title {
    height: 32px;
    line-height: 32px;
    padding-left: 16px;
    color: #a6e8ff;
    font-size: 18px;
    background: url("../../../assets/bg_title.png") no-repeat left;
    background-size: 160px 32px;
  }
  #right5Chart {
    background: url("../../../assets/bg06.png") no-repeat;
    background-size: 100% 100%;
  }
  .noData {
    background: url("../../../assets/default.png") no-repeat center center;
    background-size: 180px 100px;
  }
}
</style>
