<template>
  <div id="right4">
    <div class="title">商户评价</div>
    <div
      class="d-flex chartAll"
      style="width: 100%; height: 169px; padding: 10px 24px 0"
      v-if="targetData.length > 0"
    >
      <div class="list">
        <div
          id="right4Chart1"
          ref="right4Chart1"
          style="width: 56px; height: 56px"
        ></div>
        <p>满意</p>
      </div>
      <div class="list">
        <div
          id="right4Chart2"
          ref="right4Chart2"
          style="width: 56px; height: 56px"
        ></div>
        <p>一般</p>
      </div>
      <div class="list">
        <div
          id="right4Chart3"
          ref="right4Chart3"
          style="width: 56px; height: 56px"
        ></div>
        <p>不满意</p>
      </div>
      <div class="list">
        <div
          id="right4Chart4"
          ref="right4Chart4"
          style="width: 56px; height: 56px"
        ></div>
        <p>非常不满意</p>
      </div>
    </div>
    <div v-else class="noData" style="width: 100%; height: 174px"></div>
  </div>
</template>

<script>
import axios from "axios";
import { formatTime } from "@/utils/index.js";
import echarts from "echarts"; // 引入echarts
import "echarts-liquidfill";
export default {
  props: {},
  data() {
    return {
      saleType: 1,
      targetData: [],
    };
  },
  mounted() {
    // this.timer();
    this.init();
  },
  methods: {
    // 获取图表的数据
    getRechartData() {
      axios
        .post("api/sscreen/order/report", {
          action: "evaluate.stat",
          uuid: this.uuid,
        })
        .then((res) => {
          const {
            data: { data },
          } = res;
          console.log(data);
          if (data.length > 0) {
            this.targetData = data;
            let that = this;
            let times = setTimeout(() => {
              that.initWave();
              that.initWave2();
              that.initWave3();
              that.initWave4();
              clearTimeout(times);
            }, 1000);
          }
        });
    },
    initWave() {
      const hygrometer = this.$echarts.init(
        document.getElementById("right4Chart1")
      );
      let numbers = (this.targetData[0].rate / 100).toFixed(2);
      const option = {
        series: [
          {
            type: "liquidFill",
            data: [numbers],
            radius: "100%",
            outline: {
              show: false,
            },
            tooltip: {
              show: true,
            },
            color: ["#1AA25E"],
            itemStyle: {
              opacity: 1, // 波浪的透明度
              shadowBlur: 0, // 波浪的阴影范围
              shadowColor: "#fdf2df", // 阴影颜色
            },
            label: {
              normal: {
                textStyle: {
                  color: "#ffffff", // 波浪上文本颜色
                  insideColor: "#ffffff", // 波浪内部字体颜色
                  fontSize: 16,
                },
              },
            },
            backgroundStyle: {
              opacity: 0.4, // 透明度
              borderWidth: 0,
              borderColor: "#fdf2df",
              color: "#3681FF",
            },
          },
        ],
      };
      console.log("option", option);
      hygrometer.setOption(option);
    },
    initWave2() {
      const hygrometer = this.$echarts.init(
        document.getElementById("right4Chart2")
      );
      let numbers = (this.targetData[1].rate / 100).toFixed(2);
      const option = {
        series: [
          {
            type: "liquidFill",
            data: [numbers],
            radius: "100%",
            outline: {
              show: false,
            },
            tooltip: {
              show: true,
            },
            color: ["#3F9CFF"],
            itemStyle: {
              opacity: 1, // 波浪的透明度
              shadowBlur: 0, // 波浪的阴影范围
              shadowColor: "#3F9CFF", // 阴影颜色
            },
            label: {
              normal: {
                textStyle: {
                  color: "#ffffff", // 波浪上文本颜色
                  insideColor: "#ffffff", // 波浪内部字体颜色
                  fontSize: 16,
                },
              },
            },
            backgroundStyle: {
              opacity: 0.4, // 透明度
              borderWidth: 0,
              borderColor: "#fdf2df",
              color: "#3681FF",
            },
          },
        ],
      };
      console.log("option", option);
      hygrometer.setOption(option);
    },
    initWave3() {
      const hygrometer = this.$echarts.init(
        document.getElementById("right4Chart3")
      );
      let numbers = (this.targetData[2].rate / 100).toFixed(2);
      const option = {
        series: [
          {
            type: "liquidFill",
            data: [numbers],
            radius: "100%",
            outline: {
              show: false,
            },
            tooltip: {
              show: true,
            },
            color: ["#FFDA4A"],
            itemStyle: {
              opacity: 1, // 波浪的透明度
              shadowBlur: 0, // 波浪的阴影范围
              shadowColor: "#FFDA4A", // 阴影颜色
            },
            label: {
              normal: {
                textStyle: {
                  color: "#ffffff", // 波浪上文本颜色
                  insideColor: "#ffffff", // 波浪内部字体颜色
                  fontSize: 16,
                },
              },
            },
            backgroundStyle: {
              opacity: 0.4, // 透明度
              borderWidth: 0,
              borderColor: "#fdf2df",
              color: "#3681FF",
            },
          },
        ],
      };
      console.log("option", option);
      hygrometer.setOption(option);
    },
    initWave4() {
      const hygrometer = this.$echarts.init(
        document.getElementById("right4Chart4")
      );
      let numbers = (this.targetData[3].rate / 100).toFixed(2);
      const option = {
        series: [
          {
            type: "liquidFill",
            data: [numbers],
            radius: "100%",
            outline: {
              show: false,
            },
            tooltip: {
              show: true,
            },
            color: ["#DA4141"],
            itemStyle: {
              opacity: 1, // 波浪的透明度
              shadowBlur: 0, // 波浪的阴影范围
              shadowColor: "#DA4141", // 阴影颜色
            },
            label: {
              normal: {
                textStyle: {
                  color: "#ffffff", // 波浪上文本颜色
                  insideColor: "#ffffff", // 波浪内部字体颜色
                  fontSize: 16,
                },
              },
            },
            backgroundStyle: {
              opacity: 0.4, // 透明度
              borderWidth: 0,
              borderColor: "#fdf2df",
              color: "#3681FF",
            },
          },
        ],
      };
      console.log("option", option);
      hygrometer.setOption(option);
    },
    async init() {
      if (window.android) {
        var qs = await JSON.parse(window.android.getBindInfo());
        this.uuid = qs["uuid"];
      } else {
        this.uuid = this.$route.query.uuid;
      }
      this.getRechartData();
    },
  },
};
</script>

<style lang="scss">
#right4 {
  width: 100%;
  color: #ffffff;
  justify-content: space-between;
  .title {
    height: 32px;
    line-height: 32px;
    padding-left: 16px;
    color: #a6e8ff;
    font-size: 18px;
    background: url("../../../assets/bg_title.png") no-repeat left;
    background-size: 160px 32px;
  }
  .chartAll {
    flex-wrap: wrap;
    justify-content: space-between;
    background: url("../../../assets/bg06.png") no-repeat;
    background-size: 100% 100%;
    text-align: center;
  }
  .noData {
    background: url("../../../assets/default.png") no-repeat center center;
    background-size: 180px 100px;
  }
}
</style>
