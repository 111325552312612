<template>
  <div id="right1">
    <div class="box-bac"
      style="width: 100%; height: 314px;overflow: hide"
    >
      <div class="allData d-flex">
        <div class="list" v-for="(item, index) in targetData" :key="index">
          <div class="title">{{ item.title }}</div>
          <p class="amount">{{ item.amount }}</p>
        </div>
      </div>
      <div id="chatDom" style="width: 100%; height: 227px" v-if="target.length>0"></div>
        <div v-else class="noData" style="width: 100%; height: 220px"></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { formatTime } from "@/utils/index.js";

export default {
  props: {},
  data() {
    return {
      saleType: 1,
      targetData: [],
      target: [],
    };
  },
  mounted() {
    // this.timer();
    this.init();
    // this.iniChart();
  },
  methods: {
    // 获取列表
    getList() {
      axios
        .post("api/sscreen/order/report", {
          action: "floor.statistics",
          uuid: this.uuid,
        })
        .then((res) => {
          const {
            data: { data },
          } = res;
          console.log(data);
          this.targetData = [
            {
              title: "总商户数",
              amount: (data&&data.merchant_num)?data.merchant_num:0,
            },
            {
              title: "出租率",
              amount: (data&&data.rent_rate?data.rent_rate:0) + "%",
            },
            {
              title: "新入驻商户",
              amount: (data&&data.new_merchant_num)?data.new_merchant_num:0,
            },
            {
              title: "合同将到期",
              amount: (data&&data.about_expire_rate)?data.about_expire_rate:0,
            },
            {
              title: "续签率",
              amount: (data&&data.old_member_sign_rate?data.old_member_sign_rate:0) + "%",
            },
          ];
        });
    },
    // 获取图表的数据
    getRechartData() {
      axios
        .post("api/sscreen/order/report", {
          action: "floor.rate",
          uuid: this.uuid,
        })
        .then((res) => {
          const {
            data: { data },
          } = res;
          console.log(data);
          this.target = data;
          if(this.target.length>0){
          let that = this;
            let times = setTimeout(() => {
              that.iniChart();
              clearTimeout(times)
            }, 1000);
          }
        });
    },
    iniChart() {
      this.chart = this.$echarts.init(document.getElementById("chatDom"));
      let marketName = this.target.map((ele) => ele.market_name.slice(0,4));
      let rent_rate = this.target.map((ele) => ele.rent_rate);
      let sign_rate = this.target.map((ele) => ele.old_member_sign_rate);
      this.chart.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        grid: {
          top:'18%',
          bottom: "18%",
        },
        legend: {
          show:true,
          top:10,
          left:'center',
          textStyle:{
            color:'#ffffff'
          },
          data: ["市场出租数", "市场续签率"], //市场出租数,市场续签率
        },
        xAxis: [
          {
            type: "category",
            data: marketName,
            axisPointer: {
              type: "shadow",
            },
            axisLine: {
              lineStyle: {
                color: '#B3EDFF',
                opacity:0.1
              },
            },
            axisLabel: {
            interval: 0,
            rotate: 40,
          },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "",
            min: 0,
            axisLine: {
              lineStyle: {
                color: '#B3EDFF',
                opacity:0.1
              },
            },
            axisTick:{
              show:false,
            },
            nameTextStyle:{
              color:'#FFFFFF'
            },
            splitLine: {
              show: false,
            },
          },
          {
            type: "value",
            name: "",
            min: 0,
            axisLabel: {
              formatter: "{value}%",
            },
            axisLine: {
              lineStyle: {
                color: '#B3EDFF',
                opacity:0.1
              },
            },
            axisTick:{
              show:false,
            },
            splitLine: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: "市场出租数",
            type: "bar",
            tooltip: {
              valueFormatter: function (value) {
                return value;
              },
            },
            data: rent_rate,
            itemStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "#D9FD88",
                  },
                  {
                    offset: 1,
                    color: "#74FF9F",
                  },
                ],
                globalCoord: false, // 缺省为 false
              },
            },
          },
          {
            name: "市场续签率",
            type: "line",
            yAxisIndex: 1,
            symbol: "circle", //空心  折点样式
            symbolSize: 10, //折点大小
            tooltip: {
              valueFormatter: function (value) {
                return value + " %";
              },
            },
            data: sign_rate,
            itemStyle: {
              normal: {
                color: "#FC7A08", //改变折线点的颜色
                lineStyle: {
                  width: 4,
                  color: "#FC7A08", //改变折线颜色
                },
              },
            },
          },
        ],
      });
    },
    async init() {
      if (window.android) {
        var qs = await JSON.parse(window.android.getBindInfo());
        this.uuid = qs["uuid"];
      } else {
        this.uuid = this.$route.query.uuid;
      }
      this.getList();
      this.getRechartData();
    },
  },
};
</script>

<style lang="scss">
#right1 {
  width: 100%;
  color: #ffffff;
  .box-bac{
    background: url("../../../assets/bg06.png") no-repeat;
    background-size: 100% 100%;
  }
  .allData {
    width: 100%;
    justify-content: space-between;
    .list {
      width: 108px;
      height: 80px;
      text-align: center;
      line-height: 1;
      padding-top: 14px;
      background: url("../../../assets/bg06.png") no-repeat left;
      background-size: 100% 100%;
      .title {
        font-size: 20px;
        color: #a6e8ff;
        margin-bottom: 12px;
      }
      .amount {
        font-size: 24px;
        font-weight: bold;
        color: #ffffff;
      }
    }
  }
  .noData {
    background: url("../../../assets/default.png") no-repeat center center;
    background-size: 180px 100px;
  }
}
</style>
