<template>
  <div id="ileft3">
    <div class="title">市场实时销售汇总</div>
    <div style="width: 100%">
      <div class="box-bac" style="width: 100%; height: 170px; overflow: hide">
        <div id="left3Echart" style="width: 100%; height: 160px" v-if="targetData.length>0"></div>
        <div v-else class="noData" style="width: 100%; height: 160px"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { formatTime } from "@/utils/index.js";

export default {
  props: {},
  data() {
    return {
      targetData: [],
    };
  },
  mounted() {
    // this.timer();
    this.init();
    // this.iniChart();
  },
  methods: {
    // 获取图表的数据
    getRechartData() {
      axios
        .post("api/sscreen/order/report", {
          action: "sale.daily",
          uuid: this.uuid,
        })
        .then((res) => {
          const {
            data: { data },
          } = res;
          console.log(data);
          this.targetData = data.slice(0,5).map((c, index) => {
            const { sales_amount, market_name } = c;
            return {
              ...c,
              name: market_name.slice(0, 2),
              value:
                index == 0
                  ? 50
                  : index == 1
                  ? 40
                  : index == 2
                  ? 30
                  : index == 3
                  ? 20
                  : 10,
            };
          });
          if(this.targetData.length>0){
          let that = this;
            let times = setTimeout(() => {
              that.iniChart();
              clearTimeout(times)
            }, 1000);
          }
        });
    },

    iniChart() {
      const isFont = this.$route.query.type;

      this.chart = this.$echarts.init(document.getElementById("left3Echart"));
      this.chart.setOption({
        title: {
          text: "",
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c}%",
        },
        color: ["#FF4B4B", "#FF9629", "#FEE501", "#50C84A", "#55EFB1"],
        legend: {
          left: "center",
          bottom: 0,
          trigger: "item",
          formatter: "",
          itemWidth: 8,
          itemHeight: 8,
          textStyle: {
            color: "#fff",
            fontSize: 10,
          },
        },
        series: [
          {
            name: "",
            type: "funnel",
            left: "5%",
            top: "middle",
            // bottom: 60,
            width: "80%",
            min: 0,
            max: 50,
            minSize: "0%",
            maxSize: "100%",
            sort: "descending",
            gap: 0,
            height: "120",
            label: {
              show: true,
              position: "outside",
              normal: {
                show: true,
                color:'#ffffff',
                formatter: function (params) {
                  //标签内容
                  return (
                    (params.data.sales_amount?params.data.sales_amount:0)+params.data.unit
                  );
                },
              },
            },
            labelLine: {
              length: 10,
            },
            itemStyle: {
              borderWidth: 0,
            },
            emphasis: {
              label: {
                fontSize: 12,
              },
            },
            data: this.targetData,
          },
        ],
      });
    },
    // 字体适配
    FontChart(res) {
      //获取到屏幕的宽度
      var clientWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (!clientWidth) return; //报错拦截：
      let fontSize = 80 * (clientWidth / 1920);
      console.log(fontSize);
      return res * fontSize;
    },
    timer() {
      return setInterval(() => {
        this.getList();
      }, 60000);
    },
    async init() {
      if (window.android) {
        var qs = await JSON.parse(window.android.getBindInfo());
        this.uuid = qs["uuid"];
      } else {
        this.uuid = this.$route.query.uuid;
      }
      this.getRechartData();
    },
  },
};
</script>

<style lang="scss" scope>
#ileft3 {
  width: 240px;
  height: 202px;
  color: #ffffff;
  .box-bac{
    background: url("../../../assets/bg06.png") no-repeat;
    background-size: 100% 100%;
  }
  .title {
    height: 32px;
    line-height: 32px;
    padding-left: 16px;
    color: #a6e8ff;
    font-size: 18px;
    background: url("../../../assets/bg_title.png") no-repeat left;
    background-size: 160px 32px;
  }
  .noData{
    background: url("../../../assets/default.png") no-repeat 26px center;
    background-size: 180px 100px;
  }
}
</style>
